@use 'scss' as *;

.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100vh;
  position: relative;

  background-color: #000;
  background-image: url('../../assets/landing-page-img.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &::before {
    content: '';
    width: 100vw;
    height: 100vh;

    position: absolute;
    background-color: rgba($color: #000, $alpha: 0.3);
  }

  * {
    z-index: 10;
  }

  &-greeting {
    color: $color-white;
    font-size: 3rem;
    font-weight: 400;
    letter-spacing: 1px;

    @include xs {
      font-size: 2rem;
    }
  }

  &-text-wrapper {
    display: flex;

    color: $color-white;
    font-size: 3rem;

    min-width: 540px;
    padding-left: 1rem;

    @include xs {
      font-size: 2rem;

      width: 100%;
      min-width: 0;

      padding-left: calc(50vw - 172px);
    }
  }

  &-typewriter {
    margin-left: 0.8rem;

    font-weight: 500;

    @include xs {
      margin-left: 0.5rem;
    }
  }

  &-btn-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 50px;

    margin-top: 2rem;
  }

  &-btn {
    display: flex;

    color: $color-white;
    cursor: pointer;

    padding: 0.6rem 2.6rem;
    border: $color-white 2px solid;
    border-radius: 5px;
    background-color: rgba($color: #000, $alpha: 0.3);

    // * transform backwards

    // > 0.5s for hover over effects
    transition: border 0.5s ease-in-out, box-shadow 0.5s ease-in-out,
      padding 0.8s ease-in-out, background-color 0.8s ease-in-out;

    &:hover {
      border: $color-accent 2px solid;
      box-shadow: 0px 0px 10px $color-accent;
    }

    @include xs {
      padding: 0.5rem 1.5rem;
    }

    // > 0.8s for transforming back slowly
    &-text {
      font-size: 1.2rem;
      margin-right: 0.5rem;
      opacity: 1;

      transition: color 0.5s ease-in-out, font-size 0.8s ease-in-out,
        margin-right 0.8s ease-in-out, opacity 0.8s ease-in-out;

      @include xs {
        font-size: 1rem;
      }
    }

    &-icon {
      font-size: 1rem;

      align-self: center;

      transition: color 0.5s ease-in-out, transform 0.8s ease-in-out,
        font-size 0.8s ease-in-out;
    }

    &:hover &-text {
      color: $color-accent;
    }

    &:hover &-icon {
      color: $color-accent;
    }
  }

  &-transformed {
    &-btn {
      position: fixed;

      transform: translateX(calc(50vw - 45px)) translateY(calc(40vh - 40px));

      padding: 0.8rem;
      box-shadow: none;

      // * transform forwards

      transition: border 0.8s ease-in-out, box-shadow 0.8s ease-in-out,
        transform 0.8s ease-in-out, padding 0.8s ease-in-out,
        background-color 0.8s ease-in-out;

      &:hover {
        border: $color-white 2px solid;
        box-shadow: none;
        background-color: rgba($color: $color-accent, $alpha: 1);
      }

      @include xs {
        transform: translateX(calc(50vw - 30px)) translateY(calc(40vh - 30px));
        padding: 0.5rem;
      }

      &-text {
        font-size: 0;
        margin-right: 0;
        opacity: 0;

        transition: color 0.8s ease-in-out, font-size 0.8s ease-in-out,
          margin-right 0.8s ease-in-out, opacity 0.8s ease-in-out;
      }

      // > 0.8s for slow motion backwards
      &-icon {
        transform: rotate(180deg);

        font-size: 1.5rem;

        align-self: center;

        transition: color 0.8s ease-in-out, transform 0.8s ease-in-out,
          font-size 0.8s ease-in-out;

        @include xs {
          font-size: 1.2rem;
        }
      }
    }
  }
}
