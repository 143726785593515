@use 'scss' as *;

.footer {
  background-color: $color-dark;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 3rem 0;

  &-social {
    display: flex;

    a {
      display: inline-block;

      line-height: 0;
      color: $color-white;
      font-size: 2rem;

      margin: 0.5rem;
      padding: 0.5rem;

      opacity: 0.8;
      border-radius: 5px;
      transition: all 0.5s ease-in-out;

      &:hover {
        opacity: 1;
        box-shadow: 0px 0px 10px $color-accent;
        transform: scale(1.5) translateY(-5px);
      }
    }
  }

  &-text {
    color: $color-white;
    letter-spacing: 1px;

    margin-top: 2rem;

    em {
      color: $color-yellow;
    }
  }
}
