@use 'scss' as *;

.projects {
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: $bg-project;

  &-slogan {
    font-style: italic;
    font-size: 1.6rem;
    color: $color-gray;
    letter-spacing: 2px;

    margin-top: 2rem;

    @include touch {
      display: none;
    }

    @include md {
      display: unset;
      letter-spacing: 1px;
    }
  }

  &-gallery {
    margin-top: 6rem;

    @include md {
      margin-top: 10rem;
    }

    h3 {
      display: none;
    }

    & > li:nth-child(odd) {
      float: left;
      margin-left: 2rem;

      @include touch {
        margin-left: unset;
      }
    }

    & > li:nth-child(even) {
      float: right;
      margin-right: 2rem;

      @include touch {
        margin-right: unset;
      }
    }

    & > li:not(li:last-child) {
      margin-bottom: 6rem;

      @include touch {
        margin-bottom: 8rem;
      }

      @include md {
        margin-bottom: 10rem;
      }
    }
  }
}
