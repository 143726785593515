$color-accent: #ab47bc;
$color-purple: #c792ea;
$color-yellow: #ffcb6b;
$color-cyan: #89ddff;
$color-gray: #626262;
$color-black: #333533;
$color-white: #fdfffc;

$color-dark: #202331;
$color-light: #676e95;

$bg-project: #f8f9fa;
$bg-contact: #292d3e;

$font-raleway: 'Raleway', sans-serif;
$font-exo: 'Exo 2', sans-serif;
