@use 'scss' as *;

.about {
  background-color: $color-white;

  @include xs {
    display: flex;
    flex-direction: column;
  }

  .title {
    @include xs {
      align-self: center;
    }
  }

  &-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;

    margin-top: 5rem;

    @include xs {
      grid-template-columns: 1fr;
      margin-top: 3rem;
    }

    @include md {
      grid-template-columns: 1fr;
    }
  }

  .traits {
    padding-right: 2rem;

    @include xs {
      padding-right: 0;
    }

    @include md {
      padding-right: 0;
    }
  }

  &-content {
    letter-spacing: 0.5px;

    @include md {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    ul {
      height: 360px;

      @include xs {
        height: unset;
        margin-left: 1rem;
        padding-right: 1rem;
      }

      @include md {
        height: unset;
      }
    }

    li:not(li:last-child) {
      margin-bottom: 1rem;
    }

    h4 {
      font-size: 1.2rem;

      margin-bottom: 0.3rem;

      display: flex;
      align-items: center;

      position: relative;

      @include md {
        font-size: 1.6rem;
      }

      &::before {
        content: '';
        display: inline-block;

        position: absolute;
        left: -0.8rem;

        width: 6px;
        height: 6px;

        background-color: $color-accent;
        border-radius: 2px;

        animation: glitter 1.5s ease-in-out infinite;
      }
    }

    p {
      color: $color-gray;
      font-size: 1rem;
      line-height: 1.5;
      text-indent: 1rem;

      @include xs {
        text-indent: 0;
      }

      @include md {
        font-size: 1.4rem;
      }

      @include lg {
        text-indent: 0;
      }
    }
  }
}

@keyframes glitter {
  50% {
    box-shadow: 0px 0px 6px $color-accent;
  }

  100% {
    box-shadow: none;
  }
}
