@use 'scss' as *;

.navbar {
  position: fixed;
  z-index: 15;

  width: 100vw;
  padding: 0 6rem;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  height: 50px;
  background-color: $color-dark;
  border-bottom: $color-accent 2px solid;

  transform: translateY(-100%);
  transition: all 0.5s;

  @include xs {
    padding: 0 1rem;
  }

  &-links {
    display: flex;

    @include xs {
      position: absolute;
      top: 0;
      right: 0;

      width: 50%;
      height: 150vh;
      padding-top: 50px;

      background-color: #202331;
      flex-direction: column;

      align-items: center;

      transform: translateX(100%);
      transition: all 0.5s ease-out;
    }

    li {
      @include xs {
        margin: 1.5rem 0;

        transition: 0.5s;

        opacity: 0;
        transform: translateX(50px);
      }
    }

    a {
      color: $color-white;
      text-transform: uppercase;
      letter-spacing: 1px;

      margin: 0 1.5rem;

      transition: all 0.5s ease-out;

      cursor: pointer;

      &:hover {
        color: $color-yellow;
      }
    }

    .highlighted {
      color: $color-yellow;
    }
  }

  &-visible {
    transform: translateY(0);
  }

  &-active {
    @include xs {
      transform: translateX(0);
    }
  }

  &-burger {
    display: none;

    @include xs {
      display: block;
    }

    li {
      width: 2rem;
      height: 4px;
      margin: 5px;

      background-color: $color-white;

      transition: all 0.5s;
    }
  }

  &-burger-active {
    @include xs {
      li:first-child {
        transform: rotate(-45deg) translate(-5.2px, 7.5px);
      }

      li:nth-child(2) {
        opacity: 0;
      }

      li:last-child {
        transform: rotate(45deg) translate(-5.2px, -7.5px);
      }
    }
  }
}
