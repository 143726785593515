@use 'scss' as *;

.badge-wrapper {
  margin-top: 3rem;

  @include xs {
    margin-top: 5rem;
  }

  @include md {
    margin-top: 6rem;
  }

  h4 {
    font-size: 1.6rem;
    font-weight: 400;
    letter-spacing: 1px;

    margin-bottom: 1.5rem;

    @include md {
      font-size: 2rem;
      text-align: center;

      margin-bottom: 3rem;
    }
  }
}

.badges {
  color: $color-gray;
  font-family: $font-exo;

  @include md {
    text-align: center;
  }

  @include lg {
    text-align: center;
  }

  li {
    display: inline-flex;
    align-items: center;

    overflow: hidden;

    border: 2px solid $color-gray;
    border-radius: 5px;
    background-color: $color-gray;

    @include xs {
      overflow: scroll;
      &::-webkit-scrollbar {
        display: none;
      }

      max-width: calc(100vw - 2rem);
      width: max-content;
    }
  }

  li:not(li:last-child) {
    margin: 0 1rem 1rem 0;
  }

  // ! image
  div {
    display: flex;
    align-items: center;

    height: 28.59px;
    padding-left: calc(0.6rem - 2px);
    padding-right: 0.6rem;

    img {
      width: 24px;
      height: 24px;
    }
  }

  // ! text
  h5 {
    padding: 0.3rem 0.6rem;

    color: $color-white;
    font-size: 1rem;
    font-weight: 500;

    background-color: $color-gray;

    white-space: nowrap;
  }

  p {
    padding: 0.3rem 0.6rem;

    background-color: $color-white;

    white-space: nowrap;
  }
}
