@use 'scss' as *;

.contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: $bg-contact;

  .title {
    color: $color-white;
    margin-bottom: 3rem;
  }

  &-text {
    color: $color-cyan;
    font-style: italic;
    letter-spacing: 1px;

    @include xs {
      font-size: 0.9rem;

      white-space: nowrap;
    }
  }

  &-methods {
    display: flex;

    margin: 1rem 0;

    @include xs {
      flex-direction: column;
    }

    a {
      display: flex;
      align-items: center;

      color: $color-light;
      font-weight: 700;

      padding: 0.6rem 1rem;
      margin: 0.5rem;
      background-color: $color-dark;

      border-radius: 5px;
      border: transparent 2px solid;

      transition: all 0.5s ease-in-out;

      &:hover {
        color: $color-white;
        border: $color-accent 2px solid;
        box-shadow: 0 0 5px $color-accent;
      }

      svg {
        font-size: 2rem;
        margin-right: 0.3rem;
      }
    }
  }

  .contact-form {
    &-wrapper {
      margin: 0.5rem 20rem;

      @include xs {
        width: 100%;
        margin: 0;
      }

      .notification {
        padding: 0.5rem;
        margin-bottom: 0.5rem;

        color: $color-light;

        border-radius: 5px;
        border: $color-light 1px solid;
        box-shadow: 0 0 5px $color-light;

        transition: all 0.5s ease-in-out;
      }

      form {
        display: flex;
        flex-direction: column;
        min-width: 450px;

        @include xs {
          min-width: 0;
          width: 100%;
        }
      }

      input,
      textarea {
        font-size: 1rem;
        font-family: inherit;
        color: $color-white;
        outline: none;
        background-color: $color-dark;

        width: 100%;
        margin-bottom: 1rem;
        padding: 0.5rem;
        border: transparent 2px solid;
        border-radius: 5px;

        transition: all 0.5s;

        &:hover {
          border: $color-light 2px solid;
        }

        &:focus {
          border: $color-accent 2px solid;
          box-shadow: 0 0 5px $color-accent;
        }

        &::placeholder {
          color: $color-light;
        }
      }

      input:-webkit-autofill,
      textarea:-webkit-autofill {
        border: $color-light 2px solid;

        &:focus {
          border: $color-accent 2px solid;
          box-shadow: 0 0 5px $color-accent;
        }
      }
    }

    &-btn {
      display: flex;
      align-items: center;
      padding: 0.7rem 2rem;
      cursor: pointer;

      margin-top: 1.5rem;
      align-self: center;

      font-size: 1.2rem;
      font-family: inherit;
      letter-spacing: 1px;
      color: $color-white;

      border: none;
      border-radius: 5px;
      background-color: $color-accent;

      opacity: 0.8;
      transition: all 0.5s ease-in-out;

      &:hover {
        opacity: 1;
      }

      svg {
        margin-left: 0.3rem;
      }
    }
  }
}
