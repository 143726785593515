@use './scss/' as *;

@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Exo+2&display=swap');

* {
  padding: 0;
  margin: 0;

  list-style: none;
  text-decoration: none;

  box-sizing: border-box;
}

body {
  font-family: $font-raleway;
  color: $color-black;

  overflow-x: hidden;
}

.about,
.contact {
  padding: 8rem;

  @include xs {
    padding: 3rem 1rem 6rem;
  }

  @include md {
    padding: 5rem;
  }
}

.projects {
  padding: 8rem;

  @include xs {
    padding: 3rem 1rem 6rem;
  }

  @include md {
    padding: 5rem 2rem;
  }
}

h2.title {
  display: inline-block;

  font-size: 2.5rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;

  @include xs {
    margin-top: 3rem;
  }

  @include md {
    font-size: 3rem;
  }
}

h3.subtitle {
  color: $color-gray;

  font-size: 1.8rem;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 2px;

  margin-bottom: 3rem;

  text-align: center;

  @include xs {
    padding-right: 1rem;
  }

  @include md {
    font-size: 2.2rem;
  }
}

img {
  width: 100%;
}

.underline {
  width: 50%;
  height: 4px;
  margin: 1rem auto;

  border-radius: 2px;
  background-color: $color-yellow;
}

@media (min-width: 1081px) and (max-width: 1140px) {
  .about,
  .contact {
    padding: 2rem;
  }

  .projects {
    padding: 2rem 1rem;
  }
}

@media (min-width: 1141px) and (max-width: 1300px) {
  .about,
  .contact {
    padding: 4rem;
  }

  .projects {
    padding: 4rem 1rem;
  }
}
