@use 'scss' as *;

.progress-bar {
  display: flex;
  justify-content: center;

  width: 100%;
  margin-bottom: 1rem;

  font-size: 1rem;

  &-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    min-width: 140px;

    @include xs {
      min-width: 45px;
    }
  }

  &-icon {
    display: flex;
    align-items: center;

    img {
      width: 28px;
      height: 28px;

      border-radius: 5px;
    }

    img[alt='Native'] {
      padding: 2px;
    }

    img[alt='React'],
    img[alt='Native'] {
      background-color: #000;
    }

    img[alt='HTML'],
    img[alt='CSS/SASS'] {
      transform: scale(1.3);
    }
  }

  &-title {
    margin-left: 10px;

    color: $color-gray;
    font-weight: 600;

    @include xs {
      display: none;
    }
  }

  &-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    font-family: $font-exo;
    color: $color-white;

    width: 100%;
    height: 30px;

    background-color: #d8d8d8;
    border-radius: 5px;
  }

  &-grow {
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 5px;
    box-shadow: -2px 1px 3px $color-purple, 2px 1px 3px $color-accent;

    width: 0;
    height: 100%;

    opacity: 0;

    transition: 0.5s ease-out;
  }
}

.learned {
  background: linear-gradient(to right, $color-purple, $color-accent);
}

.learning {
  background: linear-gradient(
      90deg,
      rgba($color: $color-accent, $alpha: 0.33) 33%,
      $color-accent 50%,
      rgba($color: $color-accent, $alpha: 0.33) 66%
    )
    $color-purple;
  background-size: 300% 100%;

  animation: loader 1s infinite linear;

  @keyframes loader {
    0% {
      background-position: right;
    }
  }
}
